
import { PopoutsModule } from "@/store/modules/popouts";
import CustomButton from "@/components/CustomButton.vue";
import { PropType } from "vue";
import { defineComponent } from "vue";
import Captcha from "../Captcha.vue";
export default defineComponent({
  name: "ProfilePopout",
  components: { CustomButton, Captcha },
  props: {
    data: {
      type: Object as PropType<{
        callback: any;
      }>,
      required: true,
    },
  },
  data() {
    return {
      requestSent: false,
    };
  },
  computed: {},
  methods: {
    verify(token: string) {
      this.data.callback(token);
      this.close();
    },
    close() {
      PopoutsModule.ClosePopout("captcha-popout");
    },
    backgroundClick(event: any) {
      if (event.target.classList.contains("popout-background")) {
        this.close();
      }
    },
  },
});
